.SearchUrl-rangeConfig {
    background-color: #282c34;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    color: white;
    flex-wrap: wrap;
    max-width: 300px;
    padding-left: 10px;
    padding-right: 10px;
}

.SearchUrl-labelinput{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    padding-bottom: 10px;
}

.SearchUrl-label {
    white-space: pre;
    padding-right: 10px;
    padding-left: 10px;
}

.SearchUrl-toggleLabel {
    white-space: pre;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 5px;
}

.SearchUrl-groupLabel {
    white-space: pre;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 10px;
    font-weight: bold;
}

.SearchUrl-heading{
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: large;
}

.SearchUrl-rangeConfigArea{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
}

.SearchUrl-searchArea{
    display: flex;
    flex-direction: column;
    min-width: 300px;
}

.SearchUrl-numberInput{
    display: flex;
    flex-direction: row;
}

.SearchUrl-numberInputInput{
    max-width: 50px;
}

.SearchUrl-rarityCollection{
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
}

.SearchUrl-rarityArea{
    padding-left: 5px;
    padding-right: 5px;
}

.SearchUrl-labelcheckbox{
    display:flex;
    flex-direction: row;
    justify-content: left;
    align-items: stretch;
    padding-bottom: 5px;
}

input[type='checkbox'] {
    accent-color: cadetblue;
}